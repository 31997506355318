<template>
	<div class="picture-list">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>图片编辑列表</el-breadcrumb-item>
				</el-breadcrumb>
				<el-radio-group v-model="tabPosition">
					<el-radio-button label="0">未编辑</el-radio-button>
					<el-radio-button label="1">已编辑</el-radio-button>
					<el-radio-button label="">全部</el-radio-button>
				</el-radio-group>
			</div>
			<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName" @sort-change="handleSort"
				:default-sort="{prop: 'modifyTime', order: 'descending'}">
				<el-table-column label="序号" type="index" :index="indexMethod" width="60" align="center">
				</el-table-column>
				<el-table-column prop="buyCode" label="备件号" width="150"></el-table-column>
				<el-table-column prop="name" label="备件名称" width="200"></el-table-column>
				<el-table-column label="图片编辑" align="center" class-name="table-operate">
					<template slot-scope="scope">
						<div v-for="(item,index) in scope.row.imgs" :key="index" class="picture-item">
							<el-upload class="picture-uploader" name="file" :show-file-list="false"
								action="/api/admin/upload"
                                :headers="header"
								:on-success="handleUploadSuccess.bind(null,{'productId':scope.row.id,'data':item})"
								accept="image/jpeg,image/gif,image/png">
								<el-image fit="contain" :src="item.img" class="img"
									:class="{'active':item.major == 1}" @click.stop="previewImg(item)">
								</el-image>
								<div class="btn">
									<el-button size="small" type="primary">替换</el-button>
									<el-button size="small" type="primary" @click.stop="download(item.img)">下载</el-button>
									<el-button size="small" type="primary" @click.stop="mainPicture(item, scope.row.id)"
										title="设置主图">{{item.major == 1 ? '取消' : '设置'}}
									</el-button>
								</div>
							</el-upload>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="total, prev, pager, next" :total="this.total"
				:current-page.sync="query.page" :page-size="query.pagesize" @current-change="getList">
			</el-pagination>
		</div>

		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>

<script>
    import { fetchList, setMajor, replace } from "@/api/photoedit";
    export default {
		name: "examine",
		components: {},
		data() {
			return {
                header: { token: localStorage.getItem("token") },
				dialogVisible: false,
				dialogImageUrl: '',
				tabPosition: '0',
				tableData: [],
				query: {
					page: 1,
					pagesize: 10,
                    keyword: '',
                    photoEditStatus: '0'
				},
				total: ''
			};
		},
        watch: {
            tabPosition(val) {
                this.query.photoEditStatus = val;
                this.getList();
            }
        },
		created() {
			this.getList();
			document.addEventListener('visibilitychange', this.handleVisiable)
		},
		destroyed() {
			document.removeEventListener('visibilitychange', this.handleVisiable)
		},
		methods: {
			mainPicture(item, productId) {
				if (!item.major) {
					this.$confirm('确定将此图片设为主图吗?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
                        setMajor({ id: item.id, major: 1, productId: productId }).then(() => {
                            this.$message.success('设置成功');
                            this.getList();
                        }).catch(err => {
                            this.$message.error(err.message);
                        });
					}).catch(() => {

					});
				} else if (item.major == 1) {
					this.$confirm('确定取消主图?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
                        setMajor({ id: item.id, major: 0, productId: productId }).then(() => {
                            this.$message.success('设置成功');
                            this.getList();
                        }).catch(err => {
                            this.$message.error(err.message);
                        });
					}).catch(() => {

					});
				}

			},
			submitClick() {

			},
			previewImg(item) {
				this.dialogImageUrl = item.img;
				this.dialogVisible = true;
			},
			download(img) {
                //vue download image
                var a = document.createElement('a');
                a.href = img;
                a.download = img;
                a.click();
			},
			handleUploadSuccess(obj, res, file) {
                console.log(obj, res, file)
                if(res.code != 0){
                    this.$message.error(res.message);
                    return;
                }
                replace({id: obj.data.id, img: res.data.url, productId: obj.productId }).then(() => {
                    this.$message.success('替换成功');
                    obj.data.img = res.data.url
                }).catch(err => {
                    this.$message.error(err.message);
                });
			},
			handleVisiable(e) {
				if (e.target.visibilityState === 'visible') {
					this.getList()
				}
			},
			indexMethod(e) {
				console.log(e)
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
                fetchList(this.query).then(res => {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                })
			},
			handleSort(e) {
				let field = e.prop
				if (field == 'modifyTime') {
					field = 'modify_time'
				}
				console.log(e);
				if (e.order == "ascending") {
					this.query.sort = "+" + field
				} else if (e.order == "descending") {
					this.query.sort = "-" + field
				} else {
					this.query.sort = ""
				}
				// this.query.page = 1
				this.getList()
			},
		},
	};
</script>

<style lang="scss">
	@import "../../assets/css/picture-editing.scss";
</style>
